export default {
    base: "16px",
    spacing: "1rem",
    breakpoints: {
        xs: "0em" /* 0px */,
        sm: "30em" /* 480px */,
        md: "64em" /* 1024px */,
        lg: "75em" /* 1200px */
    },
    typography: {
        font: "'Open Sans', sans-serif",
        text: "1rem",
        title: "2rem"
    },
    colors: {
        "light": {
            "figma-component-outline": "rgba(75, 117, 159, 1)",
            "link-primary-600": "rgba(75, 117, 159, 1)",
            "link-primary-900": "rgba(73.19, 103.5, 135.91, 1)",
            "link-secondary-600": "rgba(159, 75, 75, 1)",
            "background-fish-footer": "rgba(234.09, 188.78, 188.78, 1)",
            "link-secondary-900": "rgba(136, 74, 74, 1)",
            "button/hollow-primary": "rgba(75, 117, 159, 1)",
            "button/hollow-primary-active": "rgba(62, 130, 197, 1)",
            "button/hollow-primary-hover": "rgba(139, 172, 205, 1)",
            "button/hollow-primary-text": "rgba(90, 128, 167, 1)",
            "button/hollow-primary-text-active": "rgba(226.95, 229.5, 232.05, 1)",
            "button/hollow-primary-text-disabled": "rgba(58, 92, 125, 1)",
            "button/hollow-primary-text-hover": "rgba(226.95, 229.5, 232.05, 1)",
            "button/hollow-secondary": "rgba(179, 110, 110, 1)",
            "button/hollow-secondary-active": "rgba(197, 62, 62, 1)",
            "button/hollow-secondary-hover": "rgba(180.02, 83.48, 83.48, 1)",
            "button/hollow-secondary-text": "rgba(167, 90, 90, 1)",
            "button/hollow-secondary-text-active": "rgba(232.05, 226.95, 226.95, 1)",
            "button/hollow-secondary-text-disabled": "rgba(130.42, 58.71, 58.71, 1)",
            "button/hollow-secondary-text-hover": "rgba(232.05, 226.95, 226.95, 1)",
            "button/regular-primary": "rgba(75, 117, 159, 1)",
            "button/primary-disabled": "rgba(159.38, 191.25, 223.13, 1)",
            "button/regular-primary-active": "rgba(43.48, 84.55, 124.82, 1)",
            "button/regular-primary-hover": "rgba(73.55, 104.02, 135.55, 1)",
            "button/regular-primary-text": "rgba(224.4, 229.5, 234.6, 0.949999988079071)",
            "button/regular-primary-text-active": "rgba(226.95, 229.5, 232.05, 1)",
            "button/regular-primary-text-hover": "rgba(226.95, 229.5, 232.05, 1)",
            "button/regular-secondary": "rgba(187, 94, 94, 1)",
            "button/regular-secondary-active": "rgba(197, 62, 62, 1)",
            "button/regular-secondary-hover": "rgba(148, 61, 61, 1)",
            "button/regular-secondary-text": "rgba(232.05, 226.95, 226.95, 1)",
            "button/secondary-disabled": "rgba(223, 159, 159, 1)",
            "button/regular-secondary-text-active": "rgba(232.05, 226.95, 226.95, 1)",
            "highlight/bg": "rgba(188.78, 211.44, 234.09, 1)",
            "highlight/bg-dark": "rgba(113.78, 153.05, 192.31, 1)",
            "highlight/bg-light": "rgba(206, 221, 236, 1)",
            "highlight/text": "rgba(240.34, 242.25, 244.16, 0.800000011920929)",
            "highlight/text-dark": "rgba(246.88, 247.56, 248.24, 1)",
            "highlight/text-light": "rgba(223.13, 229.29, 235.88, 0.949999988079071)",
            "input/bg": "rgba(220.22, 227.4, 233.68, 1)",
            "input/bg-active": "rgba(189, 202, 214, 1)",
            "input/text": "rgba(51, 63.75, 76.5, 0.8999999761581421)",
            "input/text-active": "rgba(57.37, 63.75, 70.13, 1)",
            "input/text-placeholder": "rgba(51, 63.75, 76.5, 0.44999998807907104)",
            "logo/mountain": "rgba(67, 122, 178, 1)",
            "logo/outline": "rgba(65, 84, 104, 1)",
            "logo/peak": "rgba(223.13, 228.44, 233.75, 1)",
            "logo/sun": "rgba(194, 90, 90, 1)",
            "search-section/options-text": "rgba(223.13, 229.28, 235.87, 0.949999988079071)",
            "search-section/options-text-active": "rgba(225.67, 229.5, 233.32, 1)",
            "seekbar/bar": "rgba(104, 155, 206, 1)",
            "seekbar/bar-bg": "rgba(196, 202, 208, 1)",
            "seekbar/bar-full": "rgba(132.41, 172.13, 211.84, 1)",
            "seekbar/bg": "rgba(75, 117, 159, 1)",
            "seekbar/popup-bg": "rgba(83, 117, 151, 1)",
            "seekbar/section-marker": "rgba(221, 228, 234, 1)",
            "seekbar/text-active": "rgba(226.95, 229.5, 232.05, 1)",
            "seekbar/text-disabled": "rgba(224.4, 229.5, 234.6, 0.44999998807907104)",
            "seekbar/text-inactive": "rgba(224.4, 229.5, 234.6, 0.949999988079071)",
            "dropdown/separator": "rgba(132.41, 172.13, 211.84, 1)",
            "colors/primary-100": "rgba(219, 229, 240, 1)",
            "colors/primary-200": "rgba(206, 221, 236, 1)",
            "colors/primary-300": "rgba(188.78, 211.44, 234.09, 1)",
            "colors/primary-400": "rgba(159.38, 191.25, 223.13, 1)",
            "colors/primary-450": "rgba(113.78, 153.05, 192.31, 1)",
            "colors/primary-50": "rgba(226, 232, 238, 1)",
            "colors/primary-500": "rgba(75, 117, 159, 1)",
            "colors/primary-600": "rgba(73.55, 104.02, 135.55, 1)",
            "colors/primary-700": "rgba(43.48, 84.55, 124.82, 1)",
            "colors/primary-800": "rgba(42.84, 103.44, 161.16, 1)",
            "colors/primary-900": "rgba(18.36, 92.53, 165.24, 1)",
            "colors/secondary-100": "rgba(240, 219, 219, 1)",
            "colors/secondary-200": "rgba(236, 206, 206, 1)",
            "colors/secondary-300": "rgba(234.09, 188.78, 188.78, 1)",
            "colors/secondary-400": "rgba(223, 159, 159, 1)",
            "colors/secondary-500": "rgba(159, 75, 75, 1)",
            "colors/secondary-600": "rgba(135.55, 73.55, 73.55, 1)",
            "colors/secondary-700": "rgba(124.82, 43.48, 43.48, 1)",
            "colors/secondary-800": "rgba(122, 29, 29, 1)",
            "colors/secondary-900": "rgba(135.02, 11.61, 11.61, 1)",
            "colors/special-bg": "rgba(232, 237, 242, 1)",
            "colors/text-600b": "rgba(246.88, 247.56, 248.24, 1)",
            "colors/text-300a": "rgba(36, 66, 96, 0.7699999809265137)",
            "colors/text-300b": "rgba(246.88, 247.56, 248.24, 0.6000000238418579)",
            "colors/text-600a": "rgba(36, 66, 96, 0.8799999952316284)",
            "colors/text-900b": "rgba(246.88, 247.56, 248.24, 1)",
            "colors/text-900a": "rgba(36.46, 66.3, 96.14, 1)",
            "colors/secondary-450": "rgba(107.05, 51.05, 51.05, 1)",
            "colors/text-100a": "rgba(36, 66, 96, 0.4000000059604645)",
            "header/pill-bg-active": "rgba(188.78, 211.44, 234.09, 1)",
            "header/pill-bg": "rgba(73.55, 104.02, 135.55, 1)",
            "header/bg": "rgba(75, 117, 159, 1)",
            "checkbox/off-bg": "rgba(219, 229, 240, 1)",
            "checkbox/off-stroke": "rgba(75, 117, 159, 1)",
            "checkbox/on-bg": "rgba(75, 117, 159, 1)"
        },
        "dark": {
            "figma-component-outline": "rgba(59.7, 92.44, 125.18, 1)",
            "link-primary-600": "rgba(127.93, 171.27, 213.77, 1)",
            "link-secondary-600": "rgba(214, 128, 128, 1)",
            "link-primary-900": "rgba(150.25, 188.7, 227.15, 1)",
            "background-fish-footer": "rgba(125.18, 59.7, 59.7, 1)",
            "link-secondary-900": "rgba(227, 151, 151, 1)",
            "button/hollow-primary": "rgba(100, 151, 201, 1)",
            "button/hollow-primary-active": "rgba(151, 189, 227, 1)",
            "button/hollow-primary-hover": "rgba(139, 172, 205, 1)",
            "button/hollow-primary-text": "rgba(90, 128, 167, 1)",
            "button/hollow-primary-text-active": "rgba(47.22, 66.05, 85.79, 1)",
            "button/hollow-primary-text-disabled": "rgba(59.7, 92.44, 125.18, 1)",
            "button/hollow-primary-text-hover": "rgba(47.22, 66.05, 85.79, 1)",
            "button/hollow-secondary": "rgba(180.02, 83.48, 83.48, 1)",
            "button/hollow-secondary-active": "rgba(227, 151, 151, 1)",
            "button/hollow-secondary-hover": "rgba(180.02, 83.48, 83.48, 1)",
            "button/hollow-secondary-text": "rgba(167, 90, 90, 1)",
            "button/hollow-secondary-text-active": "rgba(85.79, 47.22, 47.22, 1)",
            "button/hollow-secondary-text-disabled": "rgba(125.18, 59.7, 59.7, 1)",
            "button/hollow-secondary-text-hover": "rgba(85.79, 47.22, 47.22, 1)",
            "button/regular-primary": "rgba(59.7, 92.44, 125.18, 1)",
            "button/regular-primary-active": "rgba(100, 151, 201, 1)",
            "button/primary-disabled": "rgba(47.22, 66.05, 85.79, 1)",
            "button/regular-primary-hover": "rgba(62, 100, 138, 1)",
            "button/regular-primary-text": "rgba(224.4, 229.5, 234.6, 0.8999999761581421)",
            "button/regular-primary-text-active": "rgba(226.95, 229.5, 232.05, 1)",
            "button/regular-primary-text-hover": "rgba(224.4, 229.5, 234.6, 0.8999999761581421)",
            "button/regular-secondary": "rgba(125.18, 59.7, 59.7, 1)",
            "button/regular-secondary-active": "rgba(201, 100, 100, 1)",
            "button/regular-secondary-hover": "rgba(138, 62, 62, 1)",
            "button/secondary-disabled": "rgba(85.79, 47.22, 47.22, 1)",
            "button/regular-secondary-text": "rgba(234.6, 224.4, 224.4, 0.8999999761581421)",
            "button/regular-secondary-text-active": "rgba(232.05, 226.95, 226.95, 1)",
            "checkbox/off-bg": "rgba(47.22, 66.05, 85.79, 1)",
            "checkbox/off-stroke": "rgba(59.7, 92.44, 125.18, 1)",
            "checkbox/on-bg": "rgba(59.7, 92.44, 125.18, 1)",
            "highlight/bg": "rgba(47.76, 73.95, 100.14, 1)",
            "highlight/bg-dark": "rgba(59.7, 92.44, 125.18, 1)",
            "highlight/bg-light": "rgba(47.22, 66.05, 85.79, 1)",
            "highlight/text": "rgba(223.13, 229.5, 235.88, 0.949999988079071)",
            "highlight/text-dark": "rgba(223.13, 229.5, 235.88, 0.949999988079071)",
            "highlight/text-light": "rgba(36, 66, 96, 0.8799999952316284)",
            "input/bg": "rgba(54.35, 79.05, 103.75, 1)",
            "input/bg-active": "rgba(63, 92, 121, 1)",
            "input/text": "rgba(223.13, 229.5, 235.88, 0.949999988079071)",
            "input/text-active": "rgba(240.34, 242.25, 244.16, 1)",
            "input/text-placeholder": "rgba(223.13, 229.5, 235.88, 0.44999998807907104)",
            "logo/mountain": "rgba(63.35, 112.02, 161.9, 1)",
            "logo/outline": "rgba(47.76, 73.95, 100.14, 1)",
            "logo/peak": "rgba(204, 204, 204, 1)",
            "logo/sun": "rgba(214, 128, 128, 1)",
            "search-section/options-text": "rgba(223.13, 229.5, 235.87, 0.949999988079071)",
            "search-section/options-text-active": "rgba(223.13, 229.5, 235.87, 0.949999988079071)",
            "seekbar/bar": "rgba(91, 129, 169, 1)",
            "seekbar/bar-bg": "rgba(149, 172, 195, 1)",
            "seekbar/bar-full": "rgba(63.35, 112.02, 161.9, 1)",
            "seekbar/bg": "rgba(54.35, 79.05, 103.75, 1)",
            "seekbar/popup-bg": "rgba(62.42, 91.8, 121.18, 1)",
            "seekbar/section-marker": "rgba(137.19, 170.85, 204.51, 1)",
            "seekbar/text-active": "rgba(226.95, 229.5, 232.05, 1)",
            "seekbar/text-disabled": "rgba(224.4, 229.5, 234.6, 0.44999998807907104)",
            "seekbar/text-inactive": "rgba(224.4, 229.5, 234.6, 0.8999999761581421)",
            "colors/primary-100": "rgba(46, 61, 76, 1)",
            "colors/primary-200": "rgba(47.22, 66.05, 85.79, 1)",
            "colors/primary-300": "rgba(47.76, 73.95, 100.14, 1)",
            "colors/primary-400": "rgba(47.76, 73.95, 100.14, 1)",
            "colors/primary-450": "rgba(51.05, 79.05, 107.05, 1)",
            "colors/primary-50": "rgba(50.18, 61.2, 72.22, 1)",
            "colors/primary-500": "rgba(59.7, 92.44, 125.18, 1)",
            "colors/primary-600": "rgba(63.35, 112.02, 161.9, 1)",
            "colors/primary-700": "rgba(100, 151, 201, 1)",
            "colors/primary-800": "rgba(127.93, 171.27, 213.77, 1)",
            "colors/primary-900": "rgba(151, 189, 227, 1)",
            "colors/secondary-100": "rgba(76, 46, 46, 1)",
            "colors/secondary-200": "rgba(85.79, 47.22, 47.22, 1)",
            "colors/secondary-300": "rgba(100.14, 47.76, 47.76, 1)",
            "colors/secondary-400": "rgba(100.14, 47.76, 47.76, 1)",
            "colors/secondary-450": "rgba(107.05, 51.05, 51.05, 1)",
            "colors/secondary-500": "rgba(125.18, 59.7, 59.7, 1)",
            "colors/secondary-600": "rgba(161.9, 63.35, 63.35, 1)",
            "colors/secondary-700": "rgba(201, 100, 100, 1)",
            "colors/secondary-800": "rgba(214, 128, 128, 1)",
            "colors/secondary-900": "rgba(227, 151, 151, 1)",
            "colors/special-bg": "rgba(46, 57, 70, 1)",
            "colors/text-100a": "rgba(240.34, 242.25, 244.16, 0.25)",
            "colors/text-300a": "rgba(240.34, 242.25, 244.16, 0.6499999761581421)",
            "colors/text-300b": "rgba(240.34, 242.25, 244.16, 0.6499999761581421)",
            "colors/text-600a": "rgba(240.34, 242.25, 244.16, 0.800000011920929)",
            "colors/text-600b": "rgba(240.34, 242.25, 244.16, 0.800000011920929)",
            "colors/text-900a": "rgba(240.34, 242.25, 244.16, 1)",
            "colors/text-900b": "rgba(240.34, 242.25, 244.16, 1)",
            "header/pill-bg": "rgba(47.76, 73.95, 100.14, 1)",
            "header/pill-bg-active": "rgba(51.05, 79.05, 107.05, 1)",
            "header/bg": "rgba(47.22, 66.05, 85.79, 1)",
            "dropdown/separator": "rgba(59.7, 92.44, 125.18, 1)"
        },
        "colors": {
            "light": {},
            "dark": {}
        }
    }
};
